// SCSS
import './style.scss';

// Types
import { SectionHeaderProps } from './types';

// Components
import { FiSave } from 'react-icons/fi';
import { MdOutlineEdit, MdOutlineClear, MdOutlineAdd } from 'react-icons/md';

const SectionHeader = ({ header, subheader, editMode, setEditMode, handleSaveItems, handleAddItem }: SectionHeaderProps) => {
  return (
    <div className="perf-section-header d-flex justify-content-between border-bottom pb-1 my-2 mx-1 border-md">
      {/* Header and subheader */}
      <div>
        <div id="header" className="mb-2 ps-2">
          {header}
        </div>
        <div id="sub-header" className="mb-1 ps-2">
          {subheader}
        </div>
      </div>
      {/* Action buttons */}
      <div id="actions" className="d-flex align-items-end mb-1">
        {!editMode ? (
          <>
            {setEditMode && <MdOutlineEdit size={26} id="edit" onClick={() => setEditMode(true)} />}
            {handleSaveItems && <FiSave size={24} id="save-disabled" />}
          </>
        ) : (
          <>
            {setEditMode && (
              <>
                <MdOutlineEdit size={26} id="edit-disabled" />
                <MdOutlineClear size={26} id="cancel" onClick={() => setEditMode(false)} />
              </>
            )}
            {handleAddItem && <MdOutlineAdd size={28} id="add" onClick={() => handleAddItem()} />}
            {handleSaveItems && <FiSave size={24} id="save" onClick={() => handleSaveItems()} />}
          </>
        )}
      </div>
    </div>
  );
};

export default SectionHeader;
