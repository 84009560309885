// Icons
import { MdFilterListAlt } from 'react-icons/md';
import { ImCross } from 'react-icons/im';

// Types
import { ClearFilterIconProps } from './types';

const ClearFilterIcon = ({ filtered, handleClearFilters }: ClearFilterIconProps) => {
  return (
    <div className="clear-filters rounded-2" onClick={() => handleClearFilters()}>
      <MdFilterListAlt color={filtered ? '#838383' : '#cbcbcb'} size={24} />
      <div className="clear-filter-x rounded-circle">
        <ImCross color={filtered ? '#dc354b' : '#cbcbcb'} size={10} />
      </div>
    </div>
  );
};

export default ClearFilterIcon;
