// Hooks
import { useState, useEffect } from 'react';

import { useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'common/hooks/global';
import { useProjects } from 'common/hooks/api/projectAPI';
import { useVendors } from 'common/hooks/api/vendorAPI';

// Types
import { SortObject } from 'common/types/types';
import { Project, ProjectPatch } from 'common/models/project';
import { Vendor } from 'common/models/vendor';

// Components
import { Table } from 'react-bootstrap';
import { CgSortAz, CgSortZa } from 'react-icons/cg';

import Filters from 'common/components/filters/Filters';
import SectionHeader from 'common/components/section-header/SectionHeader';

// Utils
import orderBy from 'lodash/orderBy';

const ProjectList = () => {
  //#region Utils

  const hasBeenEdited = (p: Project) => {
    if (p.vendorID === null) p.vendorID = undefined;
    if (p.editedVendorID === null) p.editedVendorID = undefined;
    return p.vendorID !== p.editedVendorID;
  };

  const convertProjectDataForDisplay = (projects: Project[]) => {
    return orderBy(projects, ['projectNo', 'loadNo'], ['desc', 'asc']).map((p: Project) => {
      p = { ...p, filtered: false, editedVendorID: p.vendorID };
      if (!p.vendorID) p = { ...p, vendorAbbreviation: 'Unassigned' };
      return p;
    });
  };

  const convertVendorDataforDisplay = (vendors: Vendor[]) => {
    return orderBy(vendors, 'abbreviation', 'asc').map((v: Vendor) => ({ ...v, inSearch: true }));
  };
  //#endregion

  // Hooks
  const account = useAccount();
  const navigate = useNavigate();

  const { getAllProjects, updateProjects } = useProjects();
  const { getAllVendors } = useVendors();

  // State
  const loadingAPI = useAppSelector((state) => state.global.loadingAPI);

  const [currentSort, setCurrentSort] = useState<SortObject>({ prop: 'projectNo', order: 'desc' });

  const [projects, setProjects] = useState<Project[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);

  const [editMode, setEditMode] = useState(false);

  // Constants
  const filtersDetails = [
    { name: 'Project', prop: 'projectNo', selectAll: true, search: true },
    { name: 'Vendors', prop: 'vendorAbbreviation', selectAll: true, search: true }
  ];

  //#region useEffect

  // Get Load and Vendor data from the API
  useEffect(() => {
    (async () => {
      try {
        let _projects = await getAllProjects();
        setProjects(convertProjectDataForDisplay(_projects!));

        let _vendors = await getAllVendors();
        setVendors(convertVendorDataforDisplay(_vendors!));
      } catch (err) {
        navigate('/error', { replace: true });
      }
    })();
  }, [getAllProjects, getAllVendors, navigate]);

  //#endregion

  //#region Event Handlers

  // Sort table by prop
  const handleSortTable = (prop: string) => {
    if (prop === currentSort.prop && currentSort.order === 'desc') {
      setProjects(orderBy(projects, prop, 'asc'));
      setCurrentSort({ prop: prop, order: 'asc' });
    } else if (prop === currentSort.prop && currentSort.order === 'asc') {
      setProjects(orderBy(projects, prop, 'desc'));
      setCurrentSort({ prop: prop, order: 'desc' });
    } else {
      setProjects(orderBy(projects, prop, 'asc'));
      setCurrentSort({ prop: prop, order: 'asc' });
    }
  };

  const handleChangeVendor = (vendorID: number | undefined | string, projectID: number) => {
    if (vendorID === 'Unassigned') vendorID = undefined;

    const abbr = vendors.find((v) => v.id.toString() === vendorID)?.abbreviation;

    // Assign edited value
    let _project = projects.find((p: Project) => p.id === projectID);
    if (_project?.vendorID) _project.vendorID = _project.vendorID.toString();
    if (_project?.vendorID === null) _project.vendorID = undefined;

    setProjects(projects.map((p) => (p.id === projectID ? { ...p, vendorAbbreviation: abbr, editedVendorID: vendorID } : p)));
  };

  const handleSaveItems = async () => {
    // Grabs only projects that were edited and converts Project to ProjectPatch
    try {
      const _editedProjects = projects
        .filter((p) => hasBeenEdited(p))
        .map<ProjectPatch>((p) => ({
          id: p.id,
          vendorID: p.editedVendorID,
          modifiedBy: account!.username
        }));

      await updateProjects(_editedProjects);

      let _projects = await getAllProjects();
      setProjects(convertProjectDataForDisplay(_projects!));

      setEditMode(false);
    } catch (err) {
      navigate('/error', { replace: true });
    }
  };

  //#endregion

  // useEffect

  useEffect(() => {
    document.title = `Perf SCT | Project List`;
  });

  return (
    <>
      {projects.length <= 0 && loadingAPI ? (
        <></>
      ) : (
        <>
          <Filters data={projects} setData={setProjects} filtersDetails={filtersDetails} editMode={editMode} />
          <SectionHeader
            header="Projects"
            subheader="Assign vendors to projects"
            editMode={editMode}
            setEditMode={setEditMode}
            handleSaveItems={handleSaveItems}
          />

          <div className="perf-table-4 border">
            <Table responsive>
              <thead>
                <tr>
                  <th className="sortable ps-3" onClick={() => handleSortTable('projectNo')}>
                    Project #
                    {currentSort.prop === 'projectNo' && <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>}
                  </th>
                  <th className="sortable ps-3" onClick={() => handleSortTable('vendorAbbreviation')}>
                    Vendor (Auto-Assign)
                    {currentSort.prop === 'vendorAbbreviation' && (
                      <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>
                    )}
                  </th>
                  <th className="sortable ps-3" onClick={() => handleSortTable('activeLoadCount')}>
                    Loads at Painter
                    {currentSort.prop === 'activeLoadCount' && <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>}
                  </th>
                  <th className="sortable ps-3 d-none d-sm-table-cell" onClick={() => handleSortTable('spoolCount')}>
                    Spools
                    {currentSort.prop === 'spoolCount' && <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>}
                  </th>
                </tr>
              </thead>
              <tbody>
                {projects
                  .filter((p: Project) => !p.filtered)
                  .map((p: Project, i: number) => (
                    <tr key={i} className={`${editMode && 'editing'}`}>
                      <td className="ps-3">{p.projectNo}</td>
                      {editMode ? (
                        <td>
                          <select className="rounded-1" onChange={(e) => handleChangeVendor(e.target.value, p.id)}>
                            <option>Unassigned</option>
                            {vendors.map((v: Vendor) => (
                              <option selected={v.abbreviation === p.vendorAbbreviation} key={v.abbreviation} value={v.id}>
                                {v.abbreviation}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : (
                        <>{p.vendorAbbreviation !== 'Unassigned' ? <td>{p.vendorAbbreviation}</td> : <td className="unassigned">Unassigned</td>}</>
                      )}
                      <td>{p.activeLoadCount}</td>
                      <td className="d-none d-sm-table-cell">{p.spoolCount}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

export default ProjectList;
