// SCSS
import './style.scss';

// Hooks
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useMsal } from '@azure/msal-react';

import { useAppSelector, useAppDispatch } from 'common/hooks/global';

// Types
import { Breakpoint, BreakpointName } from './types';

// Components
import { NavLink, RouteProps } from 'react-router-dom';
import { PerfNavLarge, PerfNavSmall, PerfNavXSmall } from './responsive';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

// Utils
import { loginRequest } from 'common/auth-config';
import { MSALNavClient } from 'common/auth-config';
import { expandXSmall, hideXSmall, hideSmall, expandLarge, hideLarge, hideAll, closeAll } from './slice';

export const PerfLayout = ({ children }: RouteProps) => {
  // Hooks
  const { instance } = useMsal();
  const account = useAccount();
  const dispatch = useAppDispatch();

  // State
  const xSmallNav = useAppSelector((state) => state.layout.xSmallNav);
  const smallNav = useAppSelector((state) => state.layout.smallNav);
  const fullNav = useAppSelector((state) => state.layout.largeNav);

  //Constants
  const breakpoints: Record<BreakpointName, Breakpoint> = useMemo(() => {
    return {
      xs: { size: 0 },
      sm: { size: 576 },
      md: { size: 768 },
      lg: { size: 992 },
      xl: { size: 1200 },
      xxl: { size: 1400 }
    };
  }, []);

  //#region Utils
  const navigationClient = new MSALNavClient(useNavigate());
  instance.setNavigationClient(navigationClient);
  //#endregion

  //#region EventHandlers
  const resizeHandler = useCallback(() => {
    let bp: Breakpoint = breakpoints.lg;

    if (window.innerWidth < breakpoints.sm.size) {
      bp = breakpoints.xs;
    } else if (window.innerWidth < breakpoints.lg.size) {
      bp = breakpoints.sm;
    }

    switch (bp) {
      case breakpoints.xs:
        dispatch(hideAll());
        dispatch(closeAll());

        dispatch(hideXSmall(false));
        break;

      case breakpoints.sm:
        dispatch(hideAll());
        dispatch(closeAll());

        dispatch(hideSmall(false));

        break;

      default:
        dispatch(hideAll());
        dispatch(closeAll());

        dispatch(hideLarge(false));
        dispatch(expandLarge(true));
    }
  }, [dispatch, breakpoints]);
  //#endregion

  // #region useEffect
  useEffect(() => {
    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [dispatch, resizeHandler]);
  //#endregion

  return (
    <Container fluid className="perf-layout">
      <Navbar className="perf-header justify-content-between align-items-center" variant="dark">
        <Navbar.Brand className="nav-control d-sm-none px-2 me-0" onClick={() => dispatch(expandXSmall(!xSmallNav.expanded))}>
          <i className="bi bi-list m-0"></i>
        </Navbar.Brand>

        <NavLink to="/" className="branding">
          <Navbar.Brand className="py-0 m-0 me-sm-2">
            <img src={require('assets/img/logo.png')} width="65" className="d-inline-block align-top logo" alt="" />
          </Navbar.Brand>
          <Navbar.Brand className="py-0 my-auto fw-semibold d-none d-sm-flex">Spool Coating Tracker</Navbar.Brand>
        </NavLink>

        <Navbar.Brand className="m-0 nav-control right">
          <div className="header-icon ms-3 me-2 position-relative">
            <i className={`bi bi-box-arrow-in-right ${account ? 'd-none' : ''}`} onClick={() => instance.loginRedirect(loginRequest)} />
            <i className={`logout-btn bi bi-box-arrow-in-left ${account ? '' : 'd-none'}`} onClick={() => instance.logoutRedirect()} />
            <div className="perf-tooltip">
              <div className="tooltip-text">Logout</div>
              <div className="tooltip-arrow"></div>
            </div>
          </div>
        </Navbar.Brand>
      </Navbar>

      <Row className="perf-body">
        <>
          {/* Overlay used when SM or XS is expanded */}
          <div
            hidden={!fullNav.hidden || (!smallNav.hidden && !smallNav.expanded) || (!xSmallNav.hidden && !xSmallNav.expanded)}
            className="nav-bg-overlay position-absolute m-0 p-0 h-100 w-100"
            onClick={() => dispatch(closeAll())}
          />

          {/* XS - Hidden by default. Displays when clicking button in header  */}
          <PerfNavXSmall hide={xSmallNav.hidden} />

          {/* SM - Collapsed by default. Expands/collapses when clicking button at bottom */}
          <PerfNavSmall hide={smallNav.hidden} />

          {/* LG - Expanded by default. Collapses/expands when clicking button at bottom */}
          <PerfNavLarge hide={fullNav.hidden} />
        </>
        <Col className="perf-content p-0 px-sm-2">{children}</Col>
      </Row>
    </Container>
  );
};
