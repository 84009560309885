// Hooks
import { createContext, useCallback, useEffect, useRef, useState } from 'react';

import { useAccount, useMsal } from '@azure/msal-react';

import { useAppDispatch } from 'common/hooks/global';

// Types
import { ErrorProps } from 'pages/Error/types';
import { StatusCode } from 'common/types/enums';

// Utils
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { loginRequest } from 'common/auth-config';

// Utils
import { updateLoadingAPI, setError } from 'common/store';
import axios from 'axios';

export const PerfAPIContext = createContext<AxiosInstance | null>(null);

export const PerfAPIProvider = ({ ...props }) => {
  // Hooks
  const { instance } = useMsal();

  const account = useAccount();
  const dispatch = useAppDispatch();

  // State
  const [activeReqCount, setActiveReqCount] = useState(0);

  // Constants
  const getAxiosConfig = useCallback((): AxiosRequestConfig => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return { baseURL: process.env.REACT_APP_API };
    } else {
      return { baseURL: `${window.location.origin}/api` };
    }
  }, []);

  const api = useRef(axios.create(getAxiosConfig()));

  //#region Utils
  /*
    Try to get token with MSAL acquireSilentToken function.
    This function will attempt to get the token from the cache, but if needed, it will
    make an external call to get a new token.

    If the there is an InteractionRequiredAuthError, this typically means the refresh token is
    no longer valid, and the user needs to sign in again manually. It can also be caused by
    password or policy changes.
  */
  const getToken = useCallback(
    async (config: AxiosRequestConfig) => {
      try {
        const tokenResult = await instance.acquireTokenSilent({
          scopes: ['api://perf-sct/authorize'],
          account: account!
        });
        const token = tokenResult.accessToken;

        config.headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        return config;
      } catch (error) {
        // If silent token acquisition fails, fallback to login redirect
        if (error instanceof InteractionRequiredAuthError) {
          dispatch(updateLoadingAPI(false));
          instance.acquireTokenRedirect(loginRequest);
        }

        console.log(error);
      }
    },
    [dispatch, instance, account]
  );

  /*
    Create different error messages based on the HTTP status that was returned.
    
    403 - This is caused when the user managed to trigger an API request for data they do not have access to (role based)
    401 - This is caused when the user is no longer authenticated. This could happen from leaving the webpage open for a long time.
      In the case of 401, attempt to get a new access token and if successful,  retry the request.

    500 - This is an internal error from the backend API. Check the logs there for details.
      This is the default error we create
  */
  const errorHandler = useCallback(
    async (error: AxiosError<string>) => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error);
      }

      const { response } = error;

      let err: ErrorProps = {
        status: response?.status ?? 500,
        msg: 'There was an internal server error. Contact your system administrator if the issue persists.'
      };

      switch (response?.status) {
        case StatusCode.Forbidden: {
          err.msg = response.data;
          dispatch(setError(err));
          break;
        }
        case StatusCode.Unauthorized: {
          const config = await getToken(error.config);
          if (config) return axios(config);

          break;
        }

        default:
          dispatch(setError(err));
      }

      return Promise.reject(err);
    },
    [getToken, dispatch]
  );

  //#endregion

  //#region Hooks

  /*
    Add an interceptor to the request to increment the activeReqCount var and 
    to trigger the getToken function. This ensure that if we end up with an invalid 
    token, that MSAL should catch it and acquire a new one before we send our request.

    Add an interceptor to the response to decrement the activeReqCount var and to attach the error handler.
  */
  useEffect(() => {
    const localAPI = api.current;
    const reqInterceptor = async (config: AxiosRequestConfig<any>) => {
      setActiveReqCount((a) => a + 1);
      return getToken(config);
    };

    const resInterceptor = (response: AxiosResponse<any, any>) => {
      setActiveReqCount((a) => a - 1);
      return response;
    };

    const errInterceptor = (error: AxiosError<string, any>) => {
      setActiveReqCount((a) => a - 1);
      return errorHandler(error);
    };

    const req = localAPI.interceptors.request.use(reqInterceptor);
    const res = localAPI.interceptors.response.use(resInterceptor, errInterceptor);

    return () => {
      localAPI.interceptors.request.eject(req);
      localAPI.interceptors.response.eject(res);
    };
  }, [errorHandler, getToken]);

  // If there is a non-zero amount active requests, update the loadingAPI state
  useEffect(() => {
    if (activeReqCount > 0) {
      dispatch(updateLoadingAPI(true));
    } else {
      dispatch(updateLoadingAPI(false));
    }
  }, [activeReqCount, dispatch]);

  //#endregion

  return (
    <>
      <PerfAPIContext.Provider value={api.current}>
        <>{props.children}</>
      </PerfAPIContext.Provider>
    </>
  );
};
