// Hooks
import { useEffect, useState } from 'react';

import { useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'common/hooks/global';

import { useLoads } from 'common/hooks/api/loadAPI';
import { useVendors } from 'common/hooks/api/vendorAPI';

// Types
import { SortObject } from 'common/types/types';
import { LoadBulk, LoadPatch } from 'common/models/load';
import { Vendor } from 'common/models/vendor';

// Components
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { CgSortAz, CgSortZa } from 'react-icons/cg';
import { FaRegTrashAlt } from 'react-icons/fa';

import Filters from 'common/components/filters/Filters';
import SectionHeader from 'common/components/section-header/SectionHeader';

// Utils
import orderBy from 'lodash/orderBy';

const PerfLoadsList = () => {
  useEffect(() => {
    document.title = `Perf SCT | Load List`;
  });

  // Hooks
  const account = useAccount();
  const navigate = useNavigate();

  const { getAllLoads, updateLoads, voidLoad } = useLoads();
  const { getAllVendors } = useVendors();

  // State
  const loadingAPI = useAppSelector((state) => state.global.loadingAPI);

  const [currentSort, setCurrentSort] = useState<SortObject>({ prop: 'projectNo', order: 'desc' });

  const [loads, setLoads] = useState<LoadBulk[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);

  const [editMode, setEditMode] = useState(false);

  const [deletedLoad, setDeletedLoad] = useState<LoadPatch>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Constants
  const filtersDetails = [
    { name: 'Projects', prop: 'projectNo', selectAll: true, search: true },
    { name: 'Loads', prop: 'loadNo', selectAll: true, search: true },
    { name: 'Vendors', prop: 'editedVendorAbbreviation', selectAll: true, search: true }
  ];

  //#region Utils

  const convertLoadDataForDisplay = (loads: LoadBulk[]) => {
    return orderBy(loads, ['projectNo', 'loadNo'], ['desc', 'asc']).map((l: LoadBulk) => {
      l = { ...l, editedVendorID: l.vendorID, editedVendorAbbreviation: l.vendorAbbreviation, editedTrailerNo: l.trailerNo, filtered: false };
      if (!l.vendorID) l = { ...l, editedVendorAbbreviation: 'Unassigned' };
      if (l.state === 'AtPaint') l.state = 'At Paint Yard';
      if (l.state !== 'Created' && l.state !== 'At Paint Yard') l = { ...l, filtered: true };
      return l;
    });
  };

  const hasBeenEdited = (l: LoadBulk) => {
    if (l.vendorID === null) l.vendorID = undefined;
    if (l.trailerNo === null) l.trailerNo = undefined;
    if (l.editedVendorID === null) l.editedVendorID = undefined;
    if (l.editedTrailerNo === null) l.editedTrailerNo = undefined;
    return l.vendorID !== l.editedVendorID || l.trailerNo !== l.editedTrailerNo;
  };

  const convertVendorDataForDisplay = (_vendors: Vendor[]) => {
    return orderBy(
      _vendors.map((vendor: Vendor) => ({ ...vendor, inSearch: true })),
      'abbreviation',
      'asc'
    );
  };

  //#endregion

  //#region useEffect

  // Get Load and Vendor data from the API
  useEffect(() => {
    (async () => {
      try {
        let _loads = await getAllLoads();
        setLoads(convertLoadDataForDisplay(_loads!));

        let _vendors = await getAllVendors();
        setVendors(convertVendorDataForDisplay(_vendors));
      } catch (err) {
        navigate('/error', { replace: true });
      }
    })();
  }, [getAllLoads, getAllVendors, navigate]);

  //#endregion

  //#region Event Handlers

  const handleSortTable = (prop: string) => {
    if (prop === currentSort.prop && currentSort.order === 'desc') {
      setLoads(orderBy(loads, prop, 'asc'));
      setCurrentSort({ prop: prop, order: 'asc' });
    } else if (prop === currentSort.prop && currentSort.order === 'asc') {
      setLoads(orderBy(loads, prop, 'desc'));
      setCurrentSort({ prop: prop, order: 'desc' });
    } else {
      setLoads(orderBy(loads, prop, 'asc'));
      setCurrentSort({ prop: prop, order: 'asc' });
    }
  };

  const handleChangeTrailerNo = (editedTrailerNo: string, _loadID: string) => {
    setLoads(loads.map((l) => (l.id === _loadID ? { ...l, editedTrailerNo: editedTrailerNo } : l)));
  };

  const handleChangeVendor = (editedVendorID: string | undefined, _loadID: string) => {
    if (editedVendorID === 'Unassigned') editedVendorID = undefined;

    let _abbr = vendors.find((v) => v.id.toString() === editedVendorID)?.abbreviation;
    if (_abbr === undefined) _abbr = 'Unassigned';

    setLoads(loads.map((l) => (l.id === _loadID ? { ...l, editedVendorAbbreviation: _abbr, editedVendorID: editedVendorID } : l)));
  };

  const handleSaveLoads = async () => {
    try {
      let _editedLoads = loads
        .filter((load) => hasBeenEdited(load))
        .map<LoadPatch>((load) => ({
          id: load.id,
          trailerNo: load.editedTrailerNo,
          vendorID: load.editedVendorID,
          modifiedBy: account!.username
        }));

      await updateLoads(_editedLoads);

      const _loads = await getAllLoads();
      setLoads(convertLoadDataForDisplay(_loads!));

      setEditMode(false);
    } catch (err) {
      navigate('/error', { replace: true });
    }
  };

  const handleDeleteLoad = (loadID: string) => {
    setShowDeleteModal(true);
    setDeletedLoad({ id: loadID, modifiedBy: account!.username });
  };

  const handleConfirmDeleteLoad = async () => {
    try {
      await voidLoad(deletedLoad!.id, deletedLoad!.modifiedBy);

      let _loads = await getAllLoads();
      setLoads(convertLoadDataForDisplay(_loads));

      setEditMode(false);
      setShowDeleteModal(false);
    } catch (err) {
      navigate('/error', { replace: true });
    }
  };

  //#endregion

  return (
    <>
      {loadingAPI ? (
        <></>
      ) : (
        <>
          <Filters data={loads} setData={setLoads} filtersDetails={filtersDetails} editMode={editMode} />
          <SectionHeader
            header="Loads"
            subheader="All performance paint loads"
            editMode={editMode}
            setEditMode={setEditMode}
            handleSaveItems={handleSaveLoads}
          />
          <div className="perf-table-5 border">
            <Table responsive>
              <thead>
                <tr>
                  <th className="sortable ps-3" onClick={() => handleSortTable('projectNo')}>
                    Project
                    {currentSort.prop === 'projectNo' && <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>}
                  </th>
                  <th className="sortable" onClick={() => handleSortTable('loadNo')}>
                    Load #{currentSort.prop === 'loadNo' && <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>}
                  </th>
                  <th className="sortable" onClick={() => handleSortTable('trailer')}>
                    <span>Trailer #</span>{' '}
                    {currentSort.prop === 'trailer' && <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>}
                  </th>
                  <th className="sortable pe-4" onClick={() => handleSortTable('vendorAbbreviation')}>
                    <span>Vendor</span>
                    {currentSort.prop === 'vendorAbbreviation' && (
                      <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>
                    )}
                  </th>
                  {editMode && <th></th>}
                </tr>
              </thead>
              <tbody>
                {loads
                  .filter((l: LoadBulk) => !l.filtered)
                  .map((l: LoadBulk) => (
                    <tr key={l.id} className={`${editMode && 'editing'}`} onClick={() => !editMode && navigate(`load/${l.id}`)}>
                      <td className="ps-3">{l.projectNo}</td>
                      <td>{l.loadNo}</td>
                      {editMode ? (
                        <>
                          <td>
                            <input
                              className="rounded-1"
                              placeholder="Trailer #"
                              value={l.editedTrailerNo}
                              onChange={(e) => handleChangeTrailerNo(e.target.value, l.id)}
                            />
                          </td>
                          <td>
                            <select className="rounded-1" onChange={(e) => handleChangeVendor(e.target.value, l.id)}>
                              <option>Unassigned</option>
                              {vendors.map((v: Vendor) => (
                                <option selected={v.abbreviation === l.editedVendorAbbreviation} key={v.abbreviation} value={v.id}>
                                  {v.abbreviation}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <div className="action-item-container" onClick={() => handleDeleteLoad(l.id)}>
                              <FaRegTrashAlt role="button" className="trash" />
                            </div>
                          </td>
                        </>
                      ) : (
                        <>
                          {l.editedTrailerNo ? <td>{l.editedTrailerNo}</td> : <td className="text-danger">Unassigned</td>}
                          {l.editedVendorAbbreviation !== 'Unassigned' ? (
                            <td>{l.editedVendorAbbreviation}</td>
                          ) : (
                            <td className="text-danger">Unassigned</td>
                          )}
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
      )}

      {/* Delete Modal */}
      <Modal show={showDeleteModal} className="mt-5" onHide={() => setShowDeleteModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Delete load</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this load?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleConfirmDeleteLoad}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PerfLoadsList;
