import { AxiosResponse } from 'axios';

import { useCallback, useContext, useMemo } from 'react';
import { PerfAPIContext } from 'common/components/perf-api/PerfAPIProvider';
import { Project, ProjectPatch } from 'common/models/project';

const projectPatch = 'projects';

export const useProjects = () => {
  const context = useContext(PerfAPIContext);
  const api = useMemo(() => context!, [context]);

  const getAllProjects = useCallback(async () => {
    const response = await api.request<Project[]>({
      url: projectPatch,
      method: 'GET'
    });

    return response.data;
  }, [api]);

  const getProjectById = useCallback(
    async (id: string) => {
      const response = await api.request<Project>({
        url: projectPatch + `/${id}`,
        method: 'GET'
      });

      return response.data;
    },
    [api]
  );

  const updateProjects = useCallback(
    async (projects: ProjectPatch[]) => {
      const response = await api.request<Project, AxiosResponse<Project, ProjectPatch[]>, ProjectPatch[]>({
        url: projectPatch,
        method: 'PATCH',
        data: projects
      });

      return response.data;
    },
    [api]
  );

  return { getAllProjects, getProjectById, updateProjects };
};
