// Hooks
import { useState } from 'react';
import { useLoads } from 'common/hooks/api/loadAPI';
import { useAccount } from '@azure/msal-react';

// Types
import { CommentsSectionProps } from './types';
import { Comment } from 'common/models/comment';

// Compoents
import Form from 'react-bootstrap/form';
import Button from 'react-bootstrap/button';
import CommentComp from './CommentComp';

// Utils
import orderBy from 'lodash/orderBy';
import moment from 'moment';

const CommentsSection = ({ comments, loadId, setLoad }: CommentsSectionProps) => {
  // State
  const account = useAccount();
  const { postComment, getLoadById } = useLoads();
  const [commentInput, setCommentInput] = useState<string>('');

  // Event Handlers
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (account && commentInput.length > 0) {
      e.preventDefault();

      (async () => {
        try {
          await postComment(loadId, { loadID: loadId, user: account.username, msg: commentInput, createdDate: moment().format() });
          setLoad(await getLoadById(loadId));
          setCommentInput('');
        } catch (err) {
          console.log(err);
        }
      })();
    }
  };

  return (
    <div>
      {/* List of comments */}
      <div className="bg-white">
        {comments && comments.length !== 0 ? (
          <div className="comments-container border mb-3">
            {orderBy(comments, 'Datetime', 'desc').map((comment: Comment) => (
              <CommentComp key={comment.id} comment={comment} />
            ))}
          </div>
        ) : (
          <div className="no-comments p-5 text-center mb-4">No comments on this load</div>
        )}
      </div>
      {/* Comments form */}
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            bsPrefix="form-control comment-input mx-auto"
            rows={3}
            value={commentInput}
            placeholder="Add comment..."
            onChange={(e) => setCommentInput(e.target.value)}
          />
        </Form.Group>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '-5px 0 15px 0' }}>
          <Button variant="primary" bsPrefix="comment-btn btn" type="submit">
            Add comment
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CommentsSection;
