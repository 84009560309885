// SCSS
import './style.scss';

// Hooks
import { useEffect } from 'react';
import { useAppSelector } from 'common/hooks/global';

// Hooks
import { ErrorPageProps } from './types';

import { BiError } from 'react-icons/bi';

const Error = ({ pageError }: ErrorPageProps) => {
  // State
  const err = useAppSelector((state) => state.global.error);

  // useEffect
  useEffect(() => {
    document.title = `Perf SCT | Error`;
  });

  return (
    <div id="error-page">
      <BiError id="error-icon" size={500} />
      <div id="error-desc">
        <h1>Error Code : {err?.status || pageError?.status}</h1>
        {err?.msg || pageError?.msg}
      </div>
    </div>
  );
};

export default Error;
