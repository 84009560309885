// SCSS
import './styles.scss';

// Hooks
import { useEffect, useState } from 'react';

import { useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'common/hooks/global';

import { useStatuses } from 'common/hooks/api/statusAPI';

// Types
import React from 'react';

import { Status, StatusPatch, StatusPost } from 'common/models/status';

// Components
import { FaRegTrashAlt } from 'react-icons/fa';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import SectionHeader from 'common/components/section-header/SectionHeader';

// Utils
import moment from 'moment';
import orderBy from 'lodash/orderBy';

const StatusList = () => {
  //#region Utils

  const instanceOfStatus = (object: any): object is Status => {
    return 'id' in object;
  };

  //#endregion

  // Hooks
  const account = useAccount();
  const navigate = useNavigate();

  const { getAllStatuses, updateStatuses, createStatuses, deleteStatus } = useStatuses();

  // State
  const loadingAPI = useAppSelector((state) => state.global.loadingAPI);

  const [statuses, setStatuses] = useState<Status[]>([]);
  const [editableStatuses, setEditableStatuses] = useState<(Status | StatusPost)[]>([]);

  const [editMode, setEditMode] = useState(false);

  const [deletedStatus, setDeletedStatus] = useState<StatusPatch>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [draggedIndex, setDraggedIndex] = useState<number>(-1);
  const [droppedIndex, setDroppedIndex] = useState<number>(-1);

  //#region Event Handlers

  const handleDnDBorderClass = (index: number): string => {
    if (!editMode) return '';

    // If the item being dragged was originally above the item being
    // dropped on, use a bottom border. Otherwise, use a top border.
    if (index === droppedIndex) {
      if (draggedIndex >= droppedIndex) return 'dnd-top-border';
      return 'dnd-btm-border';
    }

    return '';
  };

  const handleCreateStatus = () => {
    const _status: StatusPost = {
      name: '',
      order: 0,
      createdBy: account!.username,
      active: true
    };

    setEditableStatuses([...editableStatuses, _status]);
  };

  const handleChangeStatusOrder = (event: React.DragEvent<HTMLTableRowElement>) => {
    event.preventDefault();

    setDraggedIndex(-1);
    setDroppedIndex(-1);

    // Copy the original editableStatuses array
    let _newEditableStatuses = editableStatuses.slice();

    // Remove and get a reference to the status that was dragged
    const draggedStatus = _newEditableStatuses.splice(draggedIndex!, 1)[0];

    // Add the draggedStatus back in at the droppedIndex
    _newEditableStatuses.splice(droppedIndex!, 0, draggedStatus);

    // Find statuses that had their order changed from this and set the edited field to true
    _newEditableStatuses.forEach((status, index) => {
      if (_newEditableStatuses[index].name !== editableStatuses[index].name && instanceOfStatus(status)) status.edited = true;
    });

    setEditableStatuses(_newEditableStatuses);
  };

  const handleChangeStatusName = (index: number, name: string) => {
    setEditableStatuses(editableStatuses.map((s, i) => (index === i ? { ...s, edited: true, name: name } : s)));
  };

  const handleSaveStatuses = async () => {
    if (editableStatuses.some((status) => status.name === '')) {
      alert('Please make sure that all statuses have a name.');
      return;
    }

    try {
      let _editedStatuses: StatusPatch[] = [];
      let _newStatuses: StatusPost[] = [];

      editableStatuses.forEach((status, index) => {
        if (instanceOfStatus(status)) {
          if (status.edited)
            _editedStatuses.push({
              id: status.id,
              name: status.name,
              modifiedBy: account!.username,
              order: index + 1
            });
        } else {
          _newStatuses.push({ ...status, order: index + 1 });
        }
      });

      if (_newStatuses.length) await createStatuses(_newStatuses);
      if (_editedStatuses.length) await updateStatuses(_editedStatuses);

      let _statuses = await getAllStatuses();
      setStatuses(orderBy(_statuses, 'order', 'asc'));

      setEditMode(false);
    } catch (err) {
      navigate('/error', { replace: true });
    }
  };

  const handleDeleteStatus = (index: number, status: Status | StatusPost) => {
    // If the status to delete is a Status object, this must be a previously create status,
    // so use the delete modal. If not, this must be a StatusPost object, meaning it was create
    // during this edit, so it can just be filtered out of the editableStatuses array
    if (instanceOfStatus(status)) {
      setShowDeleteModal(true);
      setDeletedStatus({ ...status, id: status.id, modifiedBy: account!.username });
    } else {
      setEditableStatuses(editableStatuses.filter((s, i) => i !== index));
    }
  };

  const handleConfirmDeleteStatus = async () => {
    try {
      await deleteStatus(deletedStatus!.id, deletedStatus!.modifiedBy);

      let _statuses = await getAllStatuses();
      setStatuses(orderBy(_statuses, 'order', 'asc'));

      setEditMode(false);
      setShowDeleteModal(false);
    } catch (err) {
      navigate('/error', { replace: true });
    }
  };

  //#endregion

  //#region useEffect

  useEffect(() => {
    document.title = `Perf SCT | Status List`;
  });

  // If statuses changes, set the editableStatuses array to the new statuses array
  // If editMode changes, reset the editableStatuses array to clear out any unsaved changes
  //   and reset the draggedIndex and droppedIndex values
  useEffect(() => {
    setEditableStatuses([...statuses]);

    setDraggedIndex(-1);
    setDroppedIndex(-1);
  }, [statuses, editMode]);

  // Get Status data from the API
  useEffect(() => {
    (async () => {
      try {
        let _statuses = await getAllStatuses();
        setStatuses(orderBy(_statuses, 'order', 'asc'));
      } catch (err) {
        navigate('/error', { replace: true });
      }
    })();
  }, [getAllStatuses, navigate]);
  //#endregion

  return (
    <>
      {statuses.length <= 0 && loadingAPI ? (
        <></>
      ) : (
        <>
          <SectionHeader
            header="Statuses"
            subheader="Add, edit and remove statuses"
            editMode={editMode}
            setEditMode={setEditMode}
            handleSaveItems={handleSaveStatuses}
            handleAddItem={handleCreateStatus}
          />
          <div className="perf-table-2 table-no-filters border">
            <Table responsive>
              <thead>
                <tr>
                  <th id="order-col">Order</th>
                  <th>Name</th>
                  <th className="d-none d-sm-table-cell">Modified By</th>
                  <th className="text-right">Modified Date</th>
                  {editMode && <th></th>}
                </tr>
              </thead>
              <tbody>
                {editableStatuses.map((status: Status | StatusPost, index) => (
                  <tr
                    key={index}
                    draggable={editMode}
                    className={handleDnDBorderClass(index)}
                    onDragStart={() => setDraggedIndex(index)}
                    onDragOver={() => setDroppedIndex(index)}
                    onDragEnd={handleChangeStatusOrder}>
                    <td id="order-col">{index + 1}</td>

                    {editMode ? (
                      <td className={!status.name ? 'unassigned input-cell' : ' input-cell'}>
                        <input
                          name="name"
                          value={status.name}
                          placeholder="Status Name"
                          className="rounded-1 status-input"
                          onChange={(e) => handleChangeStatusName(index, e.target.value)}
                        />
                      </td>
                    ) : (
                      <td>{status.name}</td>
                    )}

                    <td className="d-none d-sm-table-cell">{instanceOfStatus(status) && status.modifiedBy ? status.modifiedBy : status.createdBy}</td>
                    <td className="text-right text-nowrap">
                      {instanceOfStatus(status) && status.modifiedDate ? moment(status.modifiedDate.toString()).fromNow() : moment().fromNow()}
                    </td>

                    {editMode && (
                      <td>
                        <td>
                          <div className="action-item-container" onClick={() => handleDeleteStatus(index, status)}>
                            <FaRegTrashAlt role="button" className="trash" />
                          </div>
                        </td>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {/* Delete Modal */}
          <Modal show={showDeleteModal} className="mt-5" onHide={() => setShowDeleteModal(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>Delete status</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this status?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Close
              </Button>
              <Button variant="danger" onClick={handleConfirmDeleteStatus}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default StatusList;
