import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LayoutState } from './types';

const init: LayoutState = {
  xSmallNav: { hidden: true, expanded: false },
  smallNav: { hidden: true, expanded: false },
  largeNav: { hidden: false, expanded: true },
  menus: []
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: init,
  reducers: {
    hideXSmall: (state, action: PayloadAction<boolean>) => {
      state.xSmallNav.hidden = action.payload;
    },
    expandXSmall: (state, action: PayloadAction<boolean>) => {
      state.xSmallNav.expanded = action.payload;
    },
    hideSmall: (state, action: PayloadAction<boolean>) => {
      state.smallNav.hidden = action.payload;
    },
    expandSmall: (state, action: PayloadAction<boolean>) => {
      state.smallNav.expanded = action.payload;
    },
    hideLarge: (state, action: PayloadAction<boolean>) => {
      state.largeNav.hidden = action.payload;
    },
    expandLarge: (state, action: PayloadAction<boolean>) => {
      state.largeNav.expanded = action.payload;
    },
    closeAll: (state) => {
      state.xSmallNav.expanded = false;
      state.smallNav.expanded = false;
      state.largeNav.expanded = false;
    },
    hideAll: (state) => {
      state.xSmallNav.hidden = true;
      state.smallNav.hidden = true;
      state.largeNav.hidden = true;
    }
  }
});

export const { expandXSmall, hideXSmall } = layoutSlice.actions;
export const { expandSmall, hideSmall } = layoutSlice.actions;
export const { expandLarge, hideLarge } = layoutSlice.actions;

export const { hideAll, closeAll } = layoutSlice.actions;

export default layoutSlice.reducer;
