import './styles.scss';

import { useState, useEffect } from 'react';
import orderBy from 'lodash/orderBy';

import SectionHeader from 'common/components/section-header/SectionHeader';

import { useVendors } from 'common/hooks/api/vendorAPI';

import { Vendor, VendorNotification } from 'common/models/vendor';
import { SortObject } from 'common/types/types';

import { Button, Form, Modal, Table } from 'react-bootstrap';

import { CgSortAz, CgSortZa } from 'react-icons/cg';
import { TiDelete } from 'react-icons/ti';
import { HiOutlineMail } from 'react-icons/hi';

import { useAccount } from '@azure/msal-react';
import { useAppSelector } from 'common/hooks/global';

const VendorList = () => {
  //#region Utils

  const hasBeenEdited = (v: Vendor) => {
    if (v.abbreviation === null) v.abbreviation = undefined;
    return v.abbreviation !== v.editedAbbreviation;
  };

  const convertVendorDataForDisplay = (_vendors: Vendor[]) => {
    return orderBy(
      _vendors.map((v: Vendor) => ({ ...v, editedAbbreviation: v.abbreviation })),
      'name',
      'asc'
    );
  };

  const validateEmail = (email: string) => {
    return (
      email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null
    );
  };

  const sortTable = (prop: string) => {
    if (prop === currentSort.prop && currentSort.order === 'desc') {
      setVendors(orderBy(vendors, prop, 'asc'));
      setCurrentSort({ prop: prop, order: 'asc' });
    } else if (prop === currentSort.prop && currentSort.order === 'asc') {
      setVendors(orderBy(vendors, prop, 'desc'));
      setCurrentSort({ prop: prop, order: 'desc' });
    } else {
      setVendors(orderBy(vendors, prop, 'asc'));
      setCurrentSort({ prop: prop, order: 'asc' });
    }
  };

  //#endregion

  // Hooks
  const account = useAccount();

  const { getAllVendors, updateVendor, updateVendors } = useVendors();

  // State
  const loadingAPI = useAppSelector((state) => state.global.loadingAPI);

  const [vendors, setVendors] = useState<Vendor[]>();
  const [editMode, setEditMode] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [currentSort, setCurrentSort] = useState<SortObject>({ prop: 'name', order: 'asc' });
  const [showModal, setShowModal] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [emails, setEmails] = useState<string[]>([]);
  const [currentVendor, setCurrentVendor] = useState<Vendor | undefined>(undefined);

  //#region  Event handlers

  const handleChangeAbbreviation = (editedAbbreviation: string, _vendorId: number) => {
    let _abbr = editedAbbreviation;
    if (_abbr.length > 12) _abbr = _abbr.slice(0, 12);
    if (_abbr.length === 0) setNameError(true);
    if (_abbr.length > 0) setNameError(false);
    setVendors(vendors!.map((v: Vendor) => (_vendorId === v.id ? { ...v, editedAbbreviation: _abbr } : v)));
  };

  const handleSaveItems = async () => {
    if (nameError) alert('All vendors must have an abbreviated name.');
    if (account && !nameError) {
      // Grabs only vendors that were edited and converts Vendor to VendorPatch
      let _editedVendors = vendors!
        .filter((v: Vendor) => hasBeenEdited(v))
        .map((v: Vendor) => ({
          id: v.id,
          name: v.name,
          abbreviation: v.editedAbbreviation!,
          modifiedBy: account.username,
          notificationEmails: v.notificationEmails.map((notifEmail) => notifEmail.email)
        }));

      try {
        await updateVendors(_editedVendors);

        let _vendors = await getAllVendors();
        setVendors(convertVendorDataForDisplay(_vendors));
        editMode && setEditMode(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSaveEmails = async () => {
    if (account && currentVendor && vendors) {
      try {
        let _editedVendor = {
          id: currentVendor.id,
          name: currentVendor.name,
          abbreviation: currentVendor.editedAbbreviation!,
          modifiedBy: account.username,
          notificationEmails: emails
        };
        await updateVendor(_editedVendor);
        setNewEmail('');
      } catch (err) {
        console.log(err);
      }
    }
    setShowModal(false);
    setCurrentVendor(undefined);
  };

  const handleEmailSelect = (v: Vendor) => {
    const emails = v.notificationEmails?.map((e: VendorNotification) => e.email) ?? [];
    setShowModal(true);
    setCurrentVendor(v);
    setEmails(emails);
  };

  // Vendor emails
  const handleAddEmail = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (account && currentVendor) {
      if (emails.includes(newEmail)) alert('This email is already assigned to this vendor.');
      if (!validateEmail(newEmail)) alert('Please enter a valid email address.');
      if (validateEmail(newEmail) && !emails.includes(newEmail)) {
        setEmails([...emails, newEmail]);
        setNewEmail('');
      }
    }
  };

  const handleDeleteEmail = async (deletedEmail: string) => {
    setEmails(emails.filter((email: string) => email !== deletedEmail));
  };

  //#endregion

  //#region useEffect

  useEffect(() => {
    document.title = `Perf SCT | Vendor List`;
  });

  // Get vendors from database
  useEffect(() => {
    (async () => {
      try {
        let _vendors = await getAllVendors();
        setVendors(convertVendorDataForDisplay(_vendors));
      } catch (err) {
        console.log(err);
      }
    })();
  }, [getAllVendors]);

  // Run on close of Modal
  useEffect(() => {
    (async () => {
      try {
        if (!showModal) {
          setNewEmail('');

          let _vendors = await getAllVendors();
          setVendors(convertVendorDataForDisplay(_vendors));
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [showModal, getAllVendors]);

  //#endregion

  return (
    <>
      {vendors && !loadingAPI && (
        <div>
          <SectionHeader
            header="Vendors"
            subheader="Edit vendor information"
            editMode={editMode}
            setEditMode={setEditMode}
            handleSaveItems={handleSaveItems}
          />
          <div className="perf-table-2 table-no-filters border">
            <Table responsive>
              <thead>
                <tr>
                  <th onClick={() => sortTable('name')} className="ps-4">
                    <span>Name</span>
                    {currentSort.prop === 'name' && (
                      <span className="ms-2">{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />}</span>
                    )}
                  </th>
                  <th onClick={() => sortTable('abbreviation')} className="pe-4">
                    <span>Abbreviated Name</span>
                    {currentSort.prop === 'abbreviation' && (
                      <span className="ms-2">{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />}</span>
                    )}
                  </th>
                  <th className="width-20"></th>
                </tr>
              </thead>
              <tbody>
                {vendors.map((v: Vendor) => (
                  <tr key={v.id} className="table-item">
                    <td className="ps-4">{v.name}</td>
                    {!editMode ? (
                      <>
                        <td className={!v.editedAbbreviation ? 'unassigned' : ''}>
                          <span>{v.editedAbbreviation ? v.editedAbbreviation : 'Unassigned'}</span>
                        </td>
                        <td className="width-20">
                          <div className="action-item-container" onClick={() => handleEmailSelect(v)}>
                            <HiOutlineMail className="mail" />
                          </div>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className={!v.editedAbbreviation ? 'unassigned input-cell pe-4' : 'input-cell pe-4'}>
                          <input
                            value={v.editedAbbreviation}
                            placeholder="Abbreviated Name"
                            className="rounded-1"
                            onChange={(e) => handleChangeAbbreviation(e.target.value, v.id)}
                          />
                        </td>
                        <td className="width-20"></td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Modal for vendor emails */}
            <Modal
              show={showModal ? true : false}
              size="lg"
              onHide={() => {
                setShowModal(false);
                setCurrentVendor(undefined);
              }}>
              <Modal.Header closeButton>
                <Modal.Title>{`Emails for ${currentVendor?.editedAbbreviation}`}</Modal.Title>
              </Modal.Header>
              <Form onSubmit={(e) => handleAddEmail(e)}>
                <Modal.Body>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <div className="email-input">
                      <Form.Control
                        type="email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        placeholder="name@example.com"
                        autoFocus
                      />
                      <Button bsPrefix="add-email-btn btn " type="submit" value="submit" onClick={(e) => handleAddEmail(e)}>
                        Add
                      </Button>
                    </div>
                  </Form.Group>
                  <div>The following emails will receive load updates:</div>
                  {emails && emails.length > 0 ? (
                    <div className="d-flex flex-wrap my-2">
                      {emails.map((email: any, index) => (
                        <div key={index} className="email-pill rounded-5 py-1 ps-3 pe-2 my-1 me-1 d-flex align-items-center justify-content-between">
                          <span className="me-1">{email}</span>
                          <span className="delete-email" onClick={() => handleDeleteEmail(email)}>
                            <TiDelete size={23} color="gray" />
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="py-3 border rounded text-center mt-3 bg-light">There are currently no emails for this vendor.</div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button bsPrefix="save-emails-btn btn btn-primary" variant="primary" onClick={() => handleSaveEmails()}>
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default VendorList;
