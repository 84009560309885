// SCSS
import 'common/styles/global.scss';

// Types
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

// Components
import App from 'App';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';

import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';

// Utils
import { store } from 'common/store';
import { msalConfig } from 'common/auth-config';

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
);
