// Types
import { IconType } from 'react-icons';

import { NavigateFunction } from 'react-router-dom';
import { Configuration, IPublicClientApplication, NavigationClient, NavigationOptions, RedirectRequest } from '@azure/msal-browser';

// Components
import { GiRefinery } from 'react-icons/gi';
import { BsTruckFlatbed, BsPaintBucket, BsUpcScan } from 'react-icons/bs';

export type Menu = {
  title: string;
  route: string;
  rbac: string[];
};

export type MenuIcon = {
  name: string;
  icon: IconType;
};

export type MSALProps = {
  instance: IPublicClientApplication;
};

export const msalConfig: Configuration = {
  auth: {
    clientId: 'e4555584-93d3-4b7a-b362-131167cb89fd',
    authority: 'https://login.microsoftonline.com/84fc2fc2-d6bc-481b-80eb-f87e3dc30f43',
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true
  }
};

export const loginRequest: RedirectRequest = {
  scopes: ['api://perf-sct/authorize']
};

export const appRoles = {
  vendor: 'vendor',
  perf: 'perf'
} as const;

export const accessMatrix = {
  perf: [appRoles.perf],
  vendor: [appRoles.perf, appRoles.vendor]
};

export const menuAccess: Menu[] = [
  { title: 'Loads', route: '/', rbac: accessMatrix.vendor },
  { title: 'Projects', route: '/projects', rbac: accessMatrix.perf },
  { title: 'Statuses', route: '/statuses', rbac: accessMatrix.perf },
  { title: 'Vendors', route: '/vendors', rbac: accessMatrix.perf }
];

export const menuIcons: MenuIcon[] = [
  { name: 'Loads', icon: BsTruckFlatbed },
  { name: 'Projects', icon: GiRefinery },
  { name: 'Statuses', icon: BsUpcScan },
  { name: 'Vendors', icon: BsPaintBucket }
];

/**
 * This custom NavigationClient is used to stop MSAL from navigating using 'window.location' and instead
 * to use the standard react router navigation. This will be used during login/logout redirects.
 *
 * Per the MSAL documentation - https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md
 */
export class MSALNavClient extends NavigationClient {
  private nav: NavigateFunction;

  constructor(nav: NavigateFunction) {
    super();
    this.nav = nav;
  }

  // This function will be called anytime MSAL needs to navigate from one page to another
  async navigateInternal(url: string, options: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      this.nav(relativePath, { replace: true });
    } else {
      this.nav(relativePath);
    }

    return false;
  }
}
