// Icons
import { MdClear } from 'react-icons/md';
import { StatusSectionProps } from './types';

// Date picker
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';

const StatusSection = ({ status, transactionDate, editMode, handleChangeStatusDate }: StatusSectionProps) => {
  return (
    <div className="status-section border-bottom p-3">
      <div className="status-header">{status.name}:</div>
      <div className="status-actions-container">
        {editMode ? (
          <>
            <ReactDatePicker
              selected={transactionDate ? new Date(transactionDate) : undefined}
              onChange={(date: Date) => {
                handleChangeStatusDate(status.id, moment(date).format());
              }}
              placeholderText="Select status date"
            />

            <div className="today-button py-1 px-2 mx-2 rounded-1" onClick={() => handleChangeStatusDate(status.id, moment().format())}>
              Today
            </div>

            <div className="clear-button py-1 px-2 bg-danger text-white rounded-1" onClick={() => handleChangeStatusDate(status.id, undefined)}>
              <MdClear size={23} />
            </div>
          </>
        ) : (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="time-ago me-2">{transactionDate && moment(transactionDate).fromNow()}</div>
              <div>{transactionDate ? moment(transactionDate).format('MM/DD/YYYY') : 'N/A'}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StatusSection;
