// SCSS
import './style.scss';

const PerfHelp = () => {
  return (
    <div className="help-wrapper overflow-auto">
      <div className="help-content mx-auto px-5 bg-white py-5">
        <h2 className="py-4 text-center text-sm-start">Applictation Help Page</h2>
        {/* Loads Page */}
        <h3 className="py-2">Loads Page</h3>
        <p>The loads page contains all loads shipped to paint and not delivered to site.</p>
        <p>
          If you click the pencil located at the top-right of the table, you will be able to edit trailer numbers or assign a vendor to a load. When a
          vendor is assigned to a load, they will receive an email alert with a link to the load page. Make sure to save your changes by selecting the
          save icon above the table.
        </p>
        {/* Single Load Page*/}
        <h3 className="py-2">Single Load Page</h3>
        <p>You are navigated to the single load page when selecting a load from the loads page. There are 3 main sections on the page:</p>
        <p>
          <ul>
            <li>
              <b>Load Info</b> - Basic information about the load
            </li>
            <li>
              <b>Statuses</b> - Section where load statuses are assigned to the load
            </li>
            <li>
              <b>Comments</b> - Discussion about the load
            </li>
          </ul>
        </p>
        {/* Projects Page */}
        <h3 className="py-2">Projects Page</h3>
        <p>
          Here you can find a list of all current projects, the vendor currently assigned to that job and the number of loads currently at the
          painter. When assigning a vendor to a project, loads will automatically be assigned to the selected vendor for all loads on that project.
        </p>
        {/* Statuses Page */}
        <h3 className="py-2">Statuses Page</h3>
        <p>
          The statuses page contains the list of statuses that are shown on the single load page. Here you can add, remove or reorder the paint
          statuses.
        </p>
        {/* Vendors Page */}
        <h3 className="py-2">Vendors Page</h3>
        <p>
          The vendors page contains a list of all paint vendors in the application. For better readability, vendor abbreviations are used throughout
          the application. You can change abbreviations on this page.
        </p>
        <p>
          Vendors will use only one log in account, but emails can be added to each vendor that will recieve alerts when loads are assigned. You can
          add these notification emails to each vendor by selecting the orange email icons next to each vendor.
        </p>
        {/* Report link */}
        <h3 className="py-2">Report Link</h3>
        <p>The last option on the side bar is the report link. This will send you to a PowerBi report with all paint loads and status information.</p>
      </div>
    </div>
  );
};

export default PerfHelp;
