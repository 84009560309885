// Hooks
import { useAccount } from '@azure/msal-react';

// Types
import { CommentCompProps } from './types';

// Utils
import moment from 'moment';

const CommentComp = ({ comment }: CommentCompProps) => {
  // Hooks
  const account = useAccount();

  return (
    <>
      {account && (
        <div className="px-3 py-3 border-bottom">
          <div className="user-time">
            <div className={comment.user === account.username! ? 'comment-name me-1 comment-me' : 'comment-name me-1 comment-them'}>
              {comment.user}
            </div>
            <div className="comment-time">{moment(comment.createdDate).fromNow()}</div>
          </div>
          <div className="comment-text">{comment.msg}</div>
        </div>
      )}
    </>
  );
};

export default CommentComp;
