// SCSS
import './style.scss';

// Hooks
import { useAppSelector } from 'common/hooks/global';

// Components
import { Spinner } from 'react-bootstrap';

export const PerfSpinner = () => {
  // State
  const loadingAPI = useAppSelector((state) => state.global.loadingAPI);

  return (
    <>
      {loadingAPI && (
        <>
          <div className="spinner-wrapper">
            <Spinner className="perf-spinner" animation="border" variant="secondary" />
          </div>
          <div className="nav-bg-overlay dark"></div>
        </>
      )}
    </>
  );
};
