// SCSS
import './style.scss';

// Hooks
import { useEffect, useState } from 'react';

// Types
import { Filter, FilterValue, FilterProps } from './types';
import { FilterDetails } from './types';

// Components
import DropdownFilter from './DropdownFilter';
import ClearFilterIcon from './ClearFilterIcon';

// Utils
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';

const Filters = ({ data, setData, filtersDetails, editMode }: FilterProps) => {
  // State
  const [filtered, setFiltered] = useState<boolean>(false);
  const [filteredOrder, setFilteredOrder] = useState<string[]>(['State']);

  //#region Utils
  const createFilters = (filtersDetails: FilterDetails[], data: any[]) => {
    // Map the array of filterDetails objects. Properties on each object includes:
    // name - name that will be used on the filter dropdown
    // prop = the name of the property on the dataset that goes with the filter
    // selectAll - boolean that decides whether or not to include a select all option (def: false)
    // search - boolean that decides whether or not to include a search box on the filter (def: false)
    let _filters: Filter[] = filtersDetails.map((fd: FilterDetails) => {
      // Grab unique values from data for each filter to use as selection items in the filter dropdown
      // Create an object for each unique value with name, selected, hide, and inSearch props
      let _filterValues = sortBy(uniq(data.map((dataItem: any) => dataItem[fd.prop]))).map((d) => ({
        name: d,
        selected: fd.name === 'State' && d !== 'Created' && d !== 'At Paint Yard' ? false : true,
        hide: false,
        inSearch: true
      }));

      // Create an object for each filter that contains the name of the filter, an array of filterValues, and the prop for the dataset
      let _filterObject: Filter = {
        name: fd.name,
        values: fd.selectAll ? [{ name: 'Select All', selected: true, hide: false, inSearch: true }].concat(_filterValues) : _filterValues,
        prop: fd.prop
      };

      // Rearrange the order of filterValues on the Vendors filter so that Unassigned appears after Select All
      if (_filterObject.name === 'Vendors') {
        _filterObject.values = _filterObject.values
          .filter((value: FilterValue) => ['Select All', 'Unassigned'].includes(value.name) === false)
          .map((value: FilterValue) => ({ ...value, name: value.name }));
        _filterObject.values.unshift(
          { name: 'Select All', selected: true, hide: false, inSearch: true },
          { name: 'Unassigned', selected: true, hide: false, inSearch: true }
        );
      }

      // Force load of state filter to only have Created and At Paint Yard checked
      if (_filterObject.name === 'State') {
        _filterObject.values = [
          { name: 'Select All', selected: false, hide: false, inSearch: true },
          { name: 'Created', selected: true, hide: false, inSearch: true },
          { name: 'At Paint Yard', selected: true, hide: false, inSearch: true }
        ];
      }

      return _filterObject;
    });

    return _filters;
  };

  // When filters update, update the filtered prop on the dataset
  const updateFilteredPropOnData = (_filters: Filter[]) => {
    // Set all data objects filtered to false
    let _data = data.map((d: any) => ({ ...d, filtered: false }));
    // Map through data object and check if any filters are filtering out that data object
    return _data.map((d: any) => {
      _filters.map((f: Filter) => {
        if (f.values.find((v: FilterValue) => v.name === d[f.prop] && !v.selected)) d.filtered = true;
        return f;
      });
      return d;
    });
  };

  // Set filters using new data set with updated "filtered" props
  const updateHidePropOnFilterValues = (_data: any, _filters: Filter[], _filteredOrder: string[] | undefined, _currentFilterName: string) => {
    return _filters.map((f: Filter) =>
      // Mapped filter is not currently selected filter
      f.name !== _currentFilterName &&
      // Current filter is before mapped filter in filteredOrder (current filter must be in filtered order) or mapped filter is not in filtered order
      ((_filteredOrder!.indexOf(_currentFilterName) < _filteredOrder!.indexOf(f.name) && _filteredOrder?.includes(_currentFilterName)) ||
        !_filteredOrder?.includes(f.name))
        ? {
            ...f,
            values: f.values.map((v: FilterValue) =>
              // If the filter value is found on a data object that is currently being filtered, hide the item, if not, show it
              _data.filter((d: any) => !d.filtered && d[f.prop] === v.name).length === 0 && v.name !== 'Select All'
                ? { ...v, hide: true }
                : { ...v, hide: false }
            )
          }
        : // If the current mapped filter was the last to be used, don't update it
          f
    );
  };

  //#endregion

  // State
  const [filters, setFilters] = useState<Filter[]>(createFilters(filtersDetails, data));

  // Event Handlers
  const handleClearFilters = () => {
    setFilters(filters.map((f: Filter) => ({ ...f, values: f.values.map((v: FilterValue) => ({ ...v, hide: false, selected: true })) })));
    setData(data.map((d: any) => ({ ...d, filtered: false })));
    setFilteredOrder([]);
  };
  // useEffect

  // When the data changes, check to see if any items are being filtered
  useEffect(() => {
    setFiltered(filters.some((f: Filter) => f.values.some((v: FilterValue) => !v.selected)));
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className="perf-filters-bar border py-2 bg-white my-3">
      {filters.length > 0 && (
        <>
          <div className="perf-filters-container">
            {/* Dropdown for each filter */}
            {filtersDetails
              .filter((filtersDetails: FilterDetails) => filtersDetails.name && filtersDetails)
              .map((filtersDetails: FilterDetails, i: number) => (
                <DropdownFilter
                  key={i}
                  name={filtersDetails.name}
                  search={filtersDetails.search}
                  filters={filters}
                  setFilters={setFilters}
                  setData={setData}
                  editMode={editMode}
                  updateFilteredPropOnData={updateFilteredPropOnData}
                  updateHidePropOnFilters={updateHidePropOnFilterValues}
                  filteredOrder={filteredOrder}
                  setFilteredOrder={setFilteredOrder}
                />
              ))}
            {/* Show clear filters button only if a filter is applied */}
            {!editMode && <ClearFilterIcon filtered={filtered} handleClearFilters={handleClearFilters} />}
          </div>
        </>
      )}
    </div>
  );
};

export default Filters;
