import { Status, StatusPatch, StatusPost } from 'common/models/status';
import { AxiosResponse } from 'axios';

import { useCallback, useContext, useMemo } from 'react';
import { PerfAPIContext } from 'common/components/perf-api/PerfAPIProvider';

const statusPath = 'statuses';

export const useStatuses = () => {
  const context = useContext(PerfAPIContext);
  const api = useMemo(() => context!, [context]);

  const getAllStatuses = useCallback(async () => {
    const response = await api.request<Status[]>({
      url: statusPath,
      method: 'GET'
    });

    return response.data;
  }, [api]);

  const getStatusById = useCallback(async () => {
    const response = await api.request<Status[]>({
      url: statusPath,
      method: 'GET'
    });

    return response.data;
  }, [api]);

  const updateStatuses = useCallback(
    async (loads: StatusPatch[]) => {
      const response = await api.request<Status, AxiosResponse<Status, StatusPatch[]>, StatusPatch[]>({
        url: statusPath,
        method: 'PATCH',
        data: loads
      });

      return response.data;
    },
    [api]
  );

  const createStatuses = useCallback(
    async (statuses: StatusPost[]) => {
      const response = await api.request<Status, AxiosResponse<Status, StatusPost[]>, StatusPost[]>({
        url: statusPath,
        method: 'POST',
        data: statuses
      });

      return response.data;
    },
    [api]
  );

  const deleteStatus = useCallback(
    async (id: number, modifiedBy: string) => {
      const response = await api.request<Status, AxiosResponse<Status, string>, string>({
        url: statusPath + `/${id}/delete`,
        method: 'PATCH',
        data: modifiedBy
      });

      return response.data;
    },
    [api]
  );

  return { getAllStatuses, getStatusById, updateStatuses, createStatuses, deleteStatus };
};
