// Hooks
import { useState } from 'react';

// Types
import { DropdownFilterProps, Filter, FilterValue } from './types';

// Components
import Dropdown from 'react-bootstrap/Dropdown';

import { Fragment } from 'react';

import { RiArrowDropDownLine } from 'react-icons/ri';
import { MdCheckBoxOutlineBlank, MdCheckBox, MdSearch, MdClose } from 'react-icons/md';

const DropdownFilter = ({
  name,
  search,
  filters,
  setFilters,
  setData,
  editMode,
  updateFilteredPropOnData,
  updateHidePropOnFilters,
  filteredOrder,
  setFilteredOrder
}: DropdownFilterProps) => {
  // State
  const currentFilter = filters.find((filter: Filter) => filter.name === name);
  const [searchText, setSearchText] = useState<string>('');

  //#region Utils

  const selectFilterValue = (filterValue: FilterValue) => {
    // Create filteredOrder which is an array of filter names that are currently being filtered with the most recently filtered having a higher index #
    let _filteredOrder = filteredOrder;
    // If the selected item is select all, change value of selected on all filter values to true
    let _filterValues =
      filterValue.name === 'Select All'
        ? currentFilter?.values.map((v: FilterValue) => (!v.hide ? { ...v, selected: true } : v))
        : currentFilter?.values.map((v: FilterValue) => (v.name === filterValue.name ? { ...v, selected: true } : v));
    // If select all is the only item not selected, select it
    if (filterValue.name !== 'Select All' && _filterValues?.find((v: any) => v.name !== 'Select All' && !v.selected) === undefined) {
      _filterValues = _filterValues!.map((v: FilterValue) => (v.name === 'Select All' ? { ...v, selected: true } : v));
    }
    // If all values are selected after the current selection, remove the current filter name from filteredOrder
    if (!_filterValues?.some((v: FilterValue) => !v.selected)) _filteredOrder = filteredOrder.filter((fo: string) => fo !== name);

    // Assign new list of filter values to the current filter and set to state
    let _currentFilter = { ...currentFilter, values: _filterValues };
    let _filters = filters.map((f: Filter) => (f.name === currentFilter!.name ? _currentFilter : f));
    let _data = updateFilteredPropOnData(_filters);
    _filters = updateHidePropOnFilters(_data, _filters, _filteredOrder, name);
    setFilters(_filters);
    setData(_data, _filters);
    setFilteredOrder(_filteredOrder);
  };

  const deselectFilterValue = (filterValue: FilterValue) => {
    // Create filteredOrder which is an array of filter names that are currently being filtered with the most recently filtered having a higher index #
    let _filteredOrder = filteredOrder;
    if (!filteredOrder.includes(name)) _filteredOrder = [...filteredOrder, name];
    // If the deselected item is select all, change value of selected on all filter values to false
    // If the deselected item is not select all, deselect both the current item and select all
    let _filterValues =
      filterValue.name === 'Select All'
        ? currentFilter?.values.map((v: FilterValue) => (!v.hide ? { ...v, selected: false } : v))
        : currentFilter?.values.map((v: FilterValue) => (v.name === filterValue.name || v.name === 'Select All' ? { ...v, selected: false } : v));
    // Assign new list of filter values to the current filter and set to state
    let _currentFilter = { ...currentFilter, values: _filterValues };
    let _filters = filters.map((f: Filter) => (f.name === currentFilter!.name ? _currentFilter : f));
    let _data = updateFilteredPropOnData(_filters);
    _filters = updateHidePropOnFilters(_data, _filters, _filteredOrder, name);
    setFilters(_filters);
    setData(_data, _filters);
    setFilteredOrder(_filteredOrder);
  };

  const isFiltered = () => currentFilter!.values.some((fv: FilterValue) => !fv.selected);

  //#endregion

  //#region Event Handlers

  const handleToggleFilterValue = (fv: FilterValue) => {
    // Select/deselect functions
    if (!fv.selected) selectFilterValue(fv);
    else deselectFilterValue(fv);
  };

  const handleChangeSearch = (inputValue: string, filterValues: FilterValue[]) => {
    setSearchText(inputValue);
    let _filterValues = filterValues.map((filterValue: FilterValue) =>
      filterValue.name.toUpperCase().includes(inputValue.toUpperCase()) ? { ...filterValue, inSearch: true } : { ...filterValue, inSearch: false }
    );
    setFilters(filters.map((filter: Filter) => (filter.name === name ? { ...filter, values: _filterValues } : filter)));
  };

  //#endregion

  return (
    <>
      {!editMode ? (
        <Dropdown autoClose="outside">
          <Dropdown.Toggle bsPrefix="perf-dropdown-filter border-0 d-flex align-items-center mx-1 pe-0">
            <div style={isFiltered() ? { fontWeight: 'bold' } : {}}>{name}</div>
            <RiArrowDropDownLine size={22} />
          </Dropdown.Toggle>

          {!editMode && (
            <Dropdown.Menu style={{ maxHeight: '50vh', overflowY: 'auto', borderRadius: 3, marginTop: 5 }}>
              {search && (
                <div className="search-container">
                  <MdSearch size={18} />
                  <input
                    className="filter-input search-input border-0"
                    spellCheck="false"
                    placeholder="Search..."
                    value={searchText}
                    onChange={(e) => currentFilter && handleChangeSearch(e.target.value, currentFilter!.values)}></input>
                  <MdClose className="clear-search" size={16} onClick={() => handleChangeSearch('', currentFilter!.values)} />
                </div>
              )}
              <>
                {/* All filter items have been removed due to other filters --- show "No Items" message */}
                {filters
                  .find((filter: Filter) => filter.name === name)!
                  .values.filter((filterValue: FilterValue) => filterValue.name !== 'Select All' && !filterValue.hide && filterValue.inSearch)
                  .length === 0 ? (
                  <Dropdown.Item bsPrefix="filter-dropdown-no-values">No values</Dropdown.Item>
                ) : (
                  <>
                    {filters
                      .find((filter: Filter) => filter.name === name)!
                      .values.map((filterValue: FilterValue, index) => (
                        // Only show items that have not been filtered out by other filters
                        <Fragment key={index}>
                          {!filterValue.hide && filterValue.inSearch && (
                            <Dropdown.Item key={filterValue.name} bsPrefix="perf-dropdown-item" onClick={() => handleToggleFilterValue(filterValue)}>
                              {filterValue.selected ? <MdCheckBox size={20} /> : <MdCheckBoxOutlineBlank size={20} color="gray" />}
                              <div style={{ marginLeft: 7 }}>{filterValue.name}</div>
                            </Dropdown.Item>
                          )}
                        </Fragment>
                      ))}
                  </>
                )}
              </>
            </Dropdown.Menu>
          )}
        </Dropdown>
      ) : (
        <div className="disabled perf-dropdown-filter border-0 d-flex align-items-center mx-1 pe-0">
          <div style={isFiltered() ? { fontWeight: 'bold' } : {}}>{name}</div>
          <RiArrowDropDownLine size={22} style={{ marginTop: 2, marginRight: -3 }} />
        </div>
      )}
    </>
  );
};

export default DropdownFilter;
