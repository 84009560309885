import { AxiosResponse } from 'axios';
import { Vendor, VendorPatch, VendorShort } from 'common/models/vendor';
import { useCallback, useContext, useMemo } from 'react';
import { PerfAPIContext } from 'common/components/perf-api/PerfAPIProvider';

const vendorPath = 'vendors';

export const useVendors = () => {
  const context = useContext(PerfAPIContext);
  const api = useMemo(() => context!, [context]);

  const getAllVendors = useCallback(async () => {
    const response = await api.request<Vendor[]>({
      url: vendorPath,
      method: 'GET'
    });

    return response.data;
  }, [api]);

  const getVendorById = useCallback(async () => {
    const response = await api.request<VendorShort[]>({
      url: vendorPath,
      method: 'GET'
    });

    return response.data;
  }, [api]);

  const getAllVendorsShort = useCallback(async () => {
    const response = await api.request<Vendor[]>({
      url: vendorPath + `/short`,
      method: 'GET'
    });

    return response.data;
  }, [api]);

  const updateNotificationEmails = useCallback(
    async (vendor: VendorPatch) => {
      const response = await api.request<Vendor, AxiosResponse<Vendor, VendorPatch>, VendorPatch>({
        url: vendorPath + `/${vendor.id}/notification-emails`,
        method: 'PATCH',
        data: vendor
      });

      return response.data;
    },
    [api]
  );

  const updateVendors = useCallback(
    async (vendors: VendorPatch[]) => {
      const response = await api.request<Vendor, AxiosResponse<Vendor, VendorPatch[]>, VendorPatch[]>({
        url: vendorPath,
        method: 'PATCH',
        data: vendors
      });

      return response.data;
    },
    [api]
  );

  const createVendor = useCallback(async () => {
    const response = await api.request<Vendor[]>({
      url: vendorPath,
      method: 'POST'
    });

    return response.data;
  }, [api]);

  return { getAllVendors, getVendorById, getAllVendorsShort, updateVendor: updateNotificationEmails, updateVendors, createVendor };
};
