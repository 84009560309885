// SCSS
import './style.scss';

// Hooks
import { useCallback, useEffect, useState } from 'react';

import { useAccount } from '@azure/msal-react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'common/hooks/global';

import { useLoads } from 'common/hooks/api/loadAPI';
import { useStatuses } from 'common/hooks/api/statusAPI';

// Types
import { Status } from 'common/models/status';
import { Load, LoadPatch, LoadStatus } from 'common/models/load';

// Components
import BackButton from 'common/components/back-button/BackButton';
import SectionHeader from 'common/components/section-header/SectionHeader';
import StatusSection from './StatusSection';
import CommentsSection from './CommentsSection';

// Utils
import orderBy from 'lodash/orderBy';

const LoadPage = () => {
  useEffect(() => {
    document.title = `Perf SCT | Load - ${id}`;
  });

  // Hooks
  const { id } = useParams();

  const account = useAccount();
  const navigate = useNavigate();

  const { getLoadById, updateTransactions } = useLoads();
  const { getAllStatuses } = useStatuses();

  // State
  const loadingAPI = useAppSelector((state) => state.global.loadingAPI);

  const [load, setLoad] = useState<Load>();
  const [loadStatuses, setLoadStatuses] = useState<LoadStatus[]>([]);

  const [statuses, setStatuses] = useState<Status[]>([]);

  const [editMode, setEditMode] = useState(false);

  //#region Utils
  const getTransactionDate = useCallback(
    (statusID: number) => {
      if (load && load.transactions) return load.transactions.find((t) => t.statusID === statusID)?.transactionDate;

      return undefined;
    },
    [load]
  );
  //#endregion

  //#region useEffect

  // If load object or statuses array changes, set the loadStatuses array to match the new load/statuses
  // If editMode changes, reset the loadStatuses array to clear out any unsaved changes
  useEffect(() => {
    if (!load || !statuses) return;

    setLoadStatuses(statuses.map((s): LoadStatus => ({ status: s, transactionDate: getTransactionDate(s.id) })));
  }, [load, statuses, editMode, getTransactionDate]);

  // Get Load and Status data from the API
  useEffect(() => {
    (async () => {
      try {
        let _load = await getLoadById(id!);
        setLoad(_load);

        let _statuses = await getAllStatuses();
        setStatuses(orderBy(_statuses, 'order', 'asc'));
      } catch (err) {
        navigate('/error', { replace: true });
      }
    })();
  }, [id, getLoadById, getAllStatuses, navigate]);
  //#endregion

  //#region Event Handlers
  const handleChangeStatusDate = (statusID: number, date: string | undefined) => {
    setLoadStatuses(loadStatuses.map((ls) => (ls.status.id === statusID ? { ...ls, transactionDate: date } : ls)));
  };

  const handleSaveStatuses = async () => {
    try {
      let _patch: LoadPatch = {
        id: load!.id,
        transactions: [],
        modifiedBy: account!.username
      };

      loadStatuses.forEach((loadStatus) => {
        if (loadStatus.transactionDate) {
          _patch.transactions!.push({
            statusID: loadStatus.status.id,
            loadID: load!.id,
            transactionDate: loadStatus.transactionDate,
            createdBy: account!.username
          });
        }
      });

      await updateTransactions(_patch);

      let _load = await getLoadById(id!);
      setLoad(_load);

      setEditMode(false);
    } catch (err) {
      navigate('/error', { replace: true });
    }

    return;
  };
  //#endregion

  return (
    <>
      {load && load.loadNo && !loadingAPI && (
        <div className="load-page-wrapper">
          <BackButton />
          {/* Load info */}

          <SectionHeader header="Load Info" subheader="See details about the load" />
          <div className="d-flex flex-row flex-wrap">
            {/* Performance load */}
            <div className="col-6 col-sm-3 col-lg p-1">
              <div className="load-info-item border rounded bg-white d-flex flex-column align-items-center justify-content-between py-2">
                <div className="load-info-key">Perf Load #</div>
                <div className="load-info-value">{load.loadNo}</div>
              </div>
            </div>
            <div className="col-6 col-sm-3 col-lg p-1">
              <div className="load-info-item border rounded bg-white d-flex flex-column align-items-center justify-content-between py-2">
                <div className="load-info-key">Trailer</div>
                <div className="load-info-value">{load.trailerNo ? load.trailerNo : 'Unassigned'}</div>
              </div>
            </div>
            <div className="col-6 col-sm-3 col-lg p-1">
              <div className="load-info-item border rounded bg-white d-flex flex-column align-items-center justify-content-between py-2">
                <div className="load-info-key">Perf Project #</div>
                <div className="load-info-value">{load.projectNo}</div>
              </div>
            </div>
            <div className="col-6 col-sm-3 col-lg p-1">
              <div className="load-info-item border rounded bg-white d-flex flex-column align-items-center justify-content-between py-2">
                <div className="load-info-key">Vendor</div>
                <div className="load-info-value">{load.vendorID ? load.vendorAbbreviation : 'Unassigned'}</div>
              </div>
            </div>
            <div className="col-6 col-sm-3 col-lg p-1">
              <div className="load-info-item border rounded bg-white d-flex flex-column align-items-center justify-content-between py-2">
                <div className="load-info-key">Vendor Load #</div>
                <div className="load-info-value">{load.vendorLoadNo ? load.vendorLoadNo : 'Unassigned'}</div>
              </div>
            </div>
          </div>
          {/* Statuses */}
          <SectionHeader
            header="Statuses"
            subheader="Update load statuses"
            editMode={editMode}
            setEditMode={setEditMode}
            handleSaveItems={handleSaveStatuses}
          />
          <div className="statuses-container border bg-white">
            {/* Assigned status
            <div className="status-section border-bottom p-3">
              <div className="status-header">Assigned:</div>
              <div className="status-actions-container">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="time-ago me-2">{load.dateAssigned && load.vendorID && moment(load.dateAssigned).fromNow()}</div>
                  <div className={load.dateAssigned && load.vendorID ? '' : 'text-danger'}>
                    {load.dateAssigned && load.vendorID ? moment(load.dateAssigned).format('MM/DD/YYYY') : 'Unassigned'}
                  </div>
                </div>
              </div>
            </div> */}
            {/* Transactional statuses */}
            {loadStatuses.map((loadStatus, index) => (
              <StatusSection
                key={index}
                status={loadStatus.status}
                transactionDate={loadStatus.transactionDate}
                editMode={editMode}
                handleChangeStatusDate={handleChangeStatusDate}
              />
            ))}
            {/* Delivered Status
            <div className="status-section border-bottom p-3">
              <div className="status-header">Delivered:</div>
              <div className="status-actions-container"></div>
              <div className={`load-delivered ${load.stateID === 2 ? 'text-success' : 'text-danger'}`}>
                <div>{load.stateID === 2 ? 'Delivered' : 'Not Delivered'}</div>
              </div>
            </div> */}
          </div>
          {/* Comments */}
          <SectionHeader header="Comments" subheader="Discussion about load" />
          <CommentsSection comments={load.comments} loadId={load.id} setLoad={setLoad} />
        </div>
      )}
    </>
  );
};

export default LoadPage;
