// Hooks
import { useEffect, useState } from 'react';

import { useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'common/hooks/global';

import { useLoads } from 'common/hooks/api/loadAPI';

// Types
import { LoadBulk, LoadPatch } from 'common/models/load';
import { SortObject } from 'common/types/types';

// Components
import Table from 'react-bootstrap/Table';

import { CgSortAz, CgSortZa } from 'react-icons/cg';

import Filters from 'common/components/filters/Filters';
import SectionHeader from 'common/components/section-header/SectionHeader';

// Utils
import orderBy from 'lodash/orderBy';

const VendorLoadList = () => {
  //#region Utils
  const convertLoadDataForDisplay = (loads: LoadBulk[]) => {
    return orderBy(loads, ['projectNo', 'loadNo'], ['desc', 'asc']).map((l: LoadBulk) => {
      l = { ...l, editedTrailerNo: l.trailerNo, editedVendorLoadNo: l.vendorLoadNo, filtered: false };
      if (l.state === 'AtPaint') l.state = 'At Paint Yard';
      if (l.state !== 'Created' && l.state !== 'At Paint Yard') l = { ...l, filtered: true };
      return l;
    });
  };

  const hasBeenEdited = (l: LoadBulk) => {
    if (l.vendorLoadNo === null) l.vendorLoadNo = undefined;
    if (l.trailerNo === null) l.trailerNo = undefined;
    if (l.editedVendorLoadNo === null) l.editedVendorLoadNo = undefined;
    if (l.editedTrailerNo === null) l.editedTrailerNo = undefined;
    return l.vendorLoadNo !== l.editedVendorLoadNo || l.trailerNo !== l.editedTrailerNo;
  };

  //#endregion

  // Hooks
  const account = useAccount();
  const navigate = useNavigate();

  const { getAllLoads, updateLoads } = useLoads();

  // State
  const loadingAPI = useAppSelector((state) => state.global.loadingAPI);

  const [currentSort, setCurrentSort] = useState<SortObject>({ prop: 'projectNo', order: 'desc' });

  const [loads, setLoads] = useState<LoadBulk[]>([]);

  const [editMode, setEditMode] = useState(false);

  // Constants
  const filtersDetails = [
    { name: 'Projects', prop: 'projectNo', selectAll: true, search: true },
    { name: 'Loads', prop: 'loadNo', selectAll: true, search: true }
  ];

  //#region useEffect

  // Get Load data from the API
  useEffect(() => {
    (async () => {
      try {
        let _loads = await getAllLoads();
        setLoads(convertLoadDataForDisplay(_loads!));
      } catch (err) {
        navigate('/error', { replace: true });
      }
    })();
  }, [getAllLoads, navigate]);

  //#endregion

  //#region Event Handlers
  const handleSortTable = (prop: string) => {
    if (prop === currentSort.prop && currentSort.order === 'desc') {
      setLoads(orderBy(loads, prop, 'asc'));
      setCurrentSort({ prop: prop, order: 'asc' });
    } else if (prop === currentSort.prop && currentSort.order === 'asc') {
      setLoads(orderBy(loads, prop, 'desc'));
      setCurrentSort({ prop: prop, order: 'desc' });
    } else {
      setLoads(orderBy(loads, prop, 'asc'));
      setCurrentSort({ prop: prop, order: 'asc' });
    }
  };

  const handleChangeTrailerNo = (trailerNo: string, loadID: string) => {
    setLoads(loads.map((l) => (l.id === loadID ? { ...l, editedTrailerNo: trailerNo } : l)));
  };

  const handleChangeLoadNo = (vendorLoadNo: string, loadID: string) => {
    setLoads(loads.map((l) => (l.id === loadID ? { ...l, editedVendorLoadNo: vendorLoadNo } : l)));
  };

  const handleSaveItems = async () => {
    try {
      const _editedLoads = loads
        .filter((load) => hasBeenEdited(load))
        .map<LoadPatch>((load) => ({
          id: load.id,
          trailerNo: load.editedTrailerNo,
          vendorLoadNo: load.editedVendorLoadNo,
          modifiedBy: account!.username
        }));

      await updateLoads(_editedLoads);

      let _loads = await getAllLoads();
      setLoads(convertLoadDataForDisplay(_loads!));

      setEditMode(false);
    } catch (err) {
      navigate('/error', { replace: true });
    }
  };

  //#endregion

  // useEffect
  useEffect(() => {
    document.title = `Perf SCT | Load List`;
  });

  return (
    <>
      {loads.length <= 0 && loadingAPI ? (
        <></>
      ) : (
        <>
          <Filters data={loads} setData={setLoads} filtersDetails={filtersDetails} editMode={editMode} />
          <SectionHeader
            header="Loads"
            subheader="All performance paint loads"
            editMode={editMode}
            setEditMode={setEditMode}
            handleSaveItems={handleSaveItems}
          />
          <div className="perf-table-5 border">
            <Table responsive>
              <thead>
                <tr>
                  <th className="sortable ps-3" onClick={() => handleSortTable('projectNo')}>
                    Project
                    {currentSort.prop === 'projectNo' && <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>}
                  </th>
                  <th className="sortable" onClick={() => handleSortTable('loadNo')}>
                    Load #{currentSort.prop === 'loadNo' && <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>}
                  </th>
                  <th className="sortable" onClick={() => handleSortTable('trailer')}>
                    <span>Trailer #</span>{' '}
                    {currentSort.prop === 'trailer' && <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>}
                  </th>
                  <th className="sortable pe-4" onClick={() => handleSortTable('vendorLoadNo')}>
                    <span>Vendor Load #</span>
                    {currentSort.prop === 'vendorLoadNo' && <>{currentSort.order === 'asc' ? <CgSortAz size={20} /> : <CgSortZa size={20} />} </>}
                  </th>
                  {editMode && <th></th>}
                </tr>
              </thead>
              <tbody>
                {loads
                  .filter((load: LoadBulk) => !load.filtered)
                  .map((load: LoadBulk) => (
                    <tr key={load.id} className="table-item clickable" onClick={() => !editMode && navigate(`load/${load.id}`)}>
                      <td className="ps-4">{load.projectNo}</td>
                      <td>{load.loadNo}</td>
                      {editMode ? (
                        <>
                          <td>
                            <input
                              className="rounded-1"
                              placeholder="Trailer #"
                              value={load.editedTrailerNo}
                              onChange={(e) => handleChangeTrailerNo(e.target.value, load.id)}
                            />
                          </td>
                          <td className={!load.editedVendorLoadNo ? 'text-danger pe-4' : ' pe-4'}>
                            <input
                              className="rounded-1"
                              placeholder="Vendor Load #"
                              value={load.editedVendorLoadNo}
                              onChange={(e) => handleChangeLoadNo(e.target.value, load.id)}
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          {load.trailerNo ? <td>{load.trailerNo}</td> : <td className="text-danger">Unassigned</td>}
                          {load.vendorLoadNo ? <td>{load.vendorLoadNo}</td> : <td className="text-danger">Unassigned</td>}
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

export default VendorLoadList;
