// SCSS
import './style.scss';

const PerfHelp = () => {
  return (
    <div className="help-wrapper overflow-auto">
      <div className="help-content mx-auto px-5 bg-white py-5">
        <h2 className="py-4 text-center text-sm-start">Applictation Help Page</h2>
        {/* Loads Page */}
        <h3 className="py-2">Loads Page</h3>
        <p>
          The loads page contains all performance paint loads assigned to you. Loads will be removed from your queue when they are delivered to site.
        </p>
        <p>
          When loads are assigned, an email alert will be sent to registered emails with a link to the load's page. If you would like to register an
          email, reach out to the Performance project manager you are working with.
        </p>
        <p>
          If you click the pencil located at the top-right of the table, you will be able to edit trailer numbers your vendor load number for each
          load. When you are finished updating load information, click the save icon to save your changes.
        </p>
        {/* Single Load Page*/}
        <h3 className="py-2">Single Load Page</h3>
        <p>You are navigated to the single load page when selecting a load from the loads page. There are 3 main sections on the page:</p>
        <p>
          <ul>
            <li>
              <b>Load Info</b> - Basic information about the load
            </li>
            <li>
              <b>Statuses</b> - Section where load statuses are assigned to the load
            </li>
            <li>
              <b>Comments</b> - Discussion about the load
            </li>
          </ul>
        </p>
        {/* Report link */}
        <h3 className="py-2">Report Link</h3>
        <p>The report link will send you to a PowerBi report with all paint loads and status information assigned to you.</p>
      </div>
    </div>
  );
};

export default PerfHelp;
