// SCSS
import './style.scss';

// Hooks
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  // Hooks
  const navigate = useNavigate();

  return (
    <>
      <div onClick={() => navigate(`/`)} className="container rounded-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
          />
        </svg>
        <div>Back to loads</div>
      </div>
    </>
  );
};

export default BackButton;
