// Hooks
import { useEffect, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';

import { useAppDispatch } from 'common/hooks/global';

// Components
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { PerfAPIProvider } from 'common/components/perf-api/PerfAPIProvider';

import Error from 'pages/Error/Error';
import LoadPage from 'pages/Loads/load-page/LoadPage';
import PerfLoadList from 'pages/Loads/perf-load-list/PerfLoadList';
import VendorLoadList from 'pages/Loads/vend-load-list/VendorLoadList';
import ProjectList from 'pages/Projects/ProjectList';
import StatusList from 'pages/Statuses/StatusList';
import VendorList from 'pages/Vendors/VendorList';

import { PerfLayout } from 'common/components/layout/Layout';
import { RouteGaurd } from 'common/components/route-gaurd/RouteGaurd';
import { PerfSpinner } from 'common/components/perf-spinner/PerfSpinner';

// Utils
import { setAuthorizedMenus } from 'common/store';
import { accessMatrix, menuAccess } from 'common/auth-config';
import PerfHelp from 'pages/Help/PerfHelp';
import VendorHelp from 'pages/Help/VendorHelp';

const App = () => {
  // Hooks
  const { instance, accounts } = useMsal();

  const account = useAccount();
  const dispatch = useAppDispatch();

  // State
  const [perfUser, setPerfUser] = useState(false);

  instance.setActiveAccount(accounts[0] || null);

  // useEffect
  useEffect(() => {
    if (!account || !account!.idTokenClaims) return;

    const currentRoles = account!.idTokenClaims!['roles'] ?? [];

    const menus = menuAccess.filter((menu) => {
      let match: boolean = false;

      menu.rbac.forEach((role) => {
        if (!match) match = currentRoles.includes(role);
      });

      return match;
    });

    dispatch(setAuthorizedMenus(menus));
    setPerfUser(currentRoles.some((role) => role === 'perf'));
  }, [account, dispatch]);

  return (
    <>
      {account && (
        <PerfAPIProvider>
          <BrowserRouter>
            <PerfLayout>
              <PerfSpinner />
              <Routes>
                <Route index element={<RouteGaurd roles={accessMatrix.vendor} Component={perfUser ? PerfLoadList : VendorLoadList} />} />
                <Route path="/help" element={<RouteGaurd roles={accessMatrix.vendor} Component={perfUser ? PerfHelp : VendorHelp} />} />

                <Route path="load/:id" element={<RouteGaurd roles={accessMatrix.vendor} Component={LoadPage} />} />
                <Route path="/projects" element={<RouteGaurd roles={accessMatrix.perf} Component={ProjectList} />} />
                <Route path="/statuses" element={<RouteGaurd roles={accessMatrix.perf} Component={StatusList} />} />
                <Route path="/vendors" element={<RouteGaurd roles={accessMatrix.perf} Component={VendorList} />} />

                <Route path="/error" element={<Error />} />
              </Routes>
            </PerfLayout>
          </BrowserRouter>
        </PerfAPIProvider>
      )}
    </>
  );
};

export default App;
