import { useCallback, useContext, useMemo } from 'react';
import { AxiosResponse } from 'axios';

import { PerfAPIContext } from 'common/components/perf-api/PerfAPIProvider';

import { Load, LoadBulk, LoadPatch, LoadQuery } from 'common/models/load';
import { Comment } from 'common/models/comment';

export const useLoads = () => {
  const loadPath = 'loads';

  const context = useContext(PerfAPIContext);
  const api = useMemo(() => context!, [context]);

  const getAllLoads = useCallback(
    async (query?: LoadQuery) => {
      const response = await api.request<LoadBulk[]>({
        url: loadPath,
        method: 'GET',
        params: {
          vendorID: query?.vendorID,
          state: query?.state
        }
      });

      return response.data;
    },
    [api]
  );

  const getLoadById = useCallback(
    async (id: string) => {
      const response = await api.request<Load>({
        url: loadPath + `/${id}`,
        method: 'GET'
      });

      return response.data;
    },
    [api]
  );

  const updateLoads = useCallback(
    async (loads: LoadPatch[]) => {
      const response = await api.request<Load, AxiosResponse<Load, LoadPatch[]>, LoadPatch[]>({
        url: loadPath,
        method: 'PATCH',
        data: loads
      });

      return response.data;
    },
    [api]
  );

  const updateDetails = useCallback(
    async (load: LoadPatch) => {
      const response = await api.request<Load, AxiosResponse<Load, LoadPatch>, LoadPatch>({
        url: loadPath + `${load.id}/details`,
        method: 'PATCH',
        data: load
      });

      return response.data;
    },
    [api]
  );

  const updateTransactions = useCallback(
    async (load: LoadPatch) => {
      const response = await api.request<Load, AxiosResponse<Load, LoadPatch>, LoadPatch>({
        url: loadPath + `/${load.id}/transactions`,
        method: 'PATCH',
        data: load
      });

      return response.data;
    },
    [api]
  );

  const postComment = useCallback(
    async (id: string, comment: Comment) => {
      const response = await api.request<Comment, AxiosResponse<Comment, Comment>, Comment>({
        url: loadPath + `/${id}/comments`,
        method: 'POST',
        data: comment
      });

      return response.data;
    },
    [api]
  );

  const deactivate = useCallback(
    async (load: LoadPatch) => {
      const response = await api.request<Load, AxiosResponse<Load, LoadPatch>, LoadPatch>({
        url: loadPath + `${load.id}/deactivate`,
        method: 'PATCH',
        data: load
      });

      return response.data;
    },
    [api]
  );

  const voidLoad = useCallback(
    async (id: string, modifiedBy: string) => {
      const response = await api.request<Load, AxiosResponse<Load, string>, string>({
        url: loadPath + `/${id}/void`,
        method: 'PATCH',
        data: modifiedBy
      });

      return response.data;
    },
    [api]
  );

  return { getAllLoads, getLoadById, updateLoads, updateDetails, updateTransactions, postComment, deactivate, voidLoad };
};
